import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './styles/main.css'; // Import global styles

// Import components using React.lazy for code-splitting
const Home = React.lazy(() => import('./components/Home/Home'));
const Dashboard = React.lazy(() => import('./components/Dashboard/Dashboard'));

const App = () => (
    <Router>
        <React.Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/dashboard" element={<Dashboard />} />
                {/* Add more routes here as needed */}
            </Routes>
        </React.Suspense>
    </Router>
);

ReactDOM.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>,
    document.getElementById('root')
);

